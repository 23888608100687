import styled from "styled-components"
import {
  Main as BaseMain,
  MainContainer as BaseMainContainer,
} from "../../../elements/Layout/styled"

export const Main = styled(BaseMain)``

export const MainContainer = styled(BaseMainContainer)``

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 25px;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`

export const Left = styled.div`
  & > div {
    border: 1px solid #ddd;
    padding: 17.5px;
    margin-bottom: 25px;
  }

  & > div > p:last-child {
    margin-bottom: 5px;
  }
`

export const Right = styled.div`
  @media screen and (max-width: 767px) {
    display: none;
  }

  & > img {
    border-radius: 12.5%;
  }
`
