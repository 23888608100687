import React from "react"
import { OutboundLink } from "gatsby-plugin-gtag"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Header from "../components/Header"
import PageHdr from "../components/PageHdr"
import img from "../img/how-right-1.jpg"
import ButtonLink from "../components/ButtonLink"
import Footer from "../components/Footer"
import {
  Main,
  MainContainer,
  Grid,
  Left,
  Right,
} from "../components/content/How/styled"

const How = () => {
  return (
    <Layout>
      <Helmet
        name="How it Works"
        description="The better (and easier) way to shop for and purchase a new AC unit."
        slug="how"
      />
      <Header />
      <PageHdr title="How the Process Works" />
      <section>
        <Main>
          <MainContainer className="main-page">
            <article>
              <h2>The simpler, better way to buy a new AC unit.</h2>
              <p>
                Bringing the ease of e-commerce to the traditional method of the
                AC system buying and installation process is what makes Shop @
                Mechanical AC so revolutionary.
              </p>

              <Grid>
                <Left>
                  <div>
                    <h3>Step 1</h3>
                    <p>
                      Use out HVAC match questionaire to find your new AC system
                      based upon your installation zip code, split or package
                      system, straight ac or heat pump and desired tonnage.
                    </p>

                    <p>
                      <ButtonLink to="/buy/1" name="Try it out" />
                    </p>
                  </div>

                  <div>
                    <h3>Step 2</h3>

                    <p>
                      Review matching systems and add your selected unit to cart
                      (as you would any other e-commerce product), choose a
                      standard or upgraded thermostat and see the total
                      (including professional installation) before you pay with
                      your credit or debit card.
                    </p>

                    <p>
                      Financing options through{" "}
                      <OutboundLink
                        href="https://www.mechanicalac.com/about/financing"
                        target="_blank"
                      >
                        Wells Fargo
                      </OutboundLink>{" "}
                      are also available.
                    </p>
                  </div>

                  <div>
                    <h3>Step 3</h3>

                    <p>
                      Prior to installation, a Mechanical AC licensed contractor
                      will perform a pre-job site inspection to make sure
                      everything you bought is just right for your home or make
                      any necessary corrections.
                    </p>
                  </div>

                  <div>
                    <h3>Step 4</h3>

                    <p>Enjoy saving time and $1,000s on your new AC system!</p>
                  </div>
                </Left>

                <Right>
                  <img src={img} alt="Florida Home" />
                </Right>
              </Grid>
            </article>
          </MainContainer>
        </Main>
      </section>
      <Footer />
    </Layout>
  )
}

export default How
